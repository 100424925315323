import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://e20e6e597fd7b750e9e0b02f6e347bd7@o520718.ingest.us.sentry.io/4508093947904000",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});